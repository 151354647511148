/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import './modules/autocomplete.js';

// Calculate
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease-in-sine'
});

// Navigation
const navigation = document.getElementById("navigation");
const main = document.querySelector("main");
const footer = document.querySelector("footer");
var activeLevel1Element = null;
var activeLevel2Element = null;
if (navigation) {
	const elements = navigation.querySelectorAll("li");

	elements.forEach(el => {

		el.addEventListener('click', async e => {

			if (e.srcElement.classList.contains('button')) {
				return;
			}

			if (el.classList.contains('has-level-1') || el.classList.contains('has-level-2')) {
				e.preventDefault();
				e.stopPropagation();

				var navigationSubmenu = el.querySelector('.navigation-submenu');
				var level2 = null;

				if (!navigationSubmenu) {
					navigationSubmenu = el.closest('.navigation-submenu');
					level2 = el.querySelector('.level-2');
					if (activeLevel2Element != el && activeLevel2Element != null) {
						activeLevel2Element.classList.remove('open');
					}
				}
				else {
					if (activeLevel2Element != null) {
						activeLevel2Element.classList.remove('open');
					}
					if (activeLevel1Element != el && activeLevel1Element != null) {
						activeLevel1Element.classList.remove('open');
					}
				}

				if (el.classList.contains('open')) {
					el.classList.remove('open');
					activeLevel1Element = null;
				}
				else {
					el.classList.add("open");

					if (el.classList.contains('has-level-1')) {
						activeLevel1Element = el;
					}

					if (el.classList.contains('has-level-2')) {
						activeLevel2Element = el;
					}
				}

				if (activeLevel1Element != null) {
					document.documentElement.classList.add('has-overlay');
				}
				else {
					document.documentElement.classList.remove('has-overlay');
				}
			}
			else {
				if (!el.classList.contains('button')) {
					e.stopPropagation();
					var a = el.querySelector(a);
					if (a !== null) {
						a.click();
					}
				}
			}
		});
	});

	const contectLinks = navigation.querySelectorAll(".contact__content a");
	contectLinks.forEach(el => {
		el.addEventListener('click', async e => {
			e.stopPropagation();
		});
	});
}

// Swiper
var sliders = document.querySelectorAll('.slider');
if (sliders.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		sliders.forEach(el => {
			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 'auto',
				watchOverflow: true,
				keyboard: {
					enabled: true
				},
				navigation: {
					nextEl: el.querySelector('.swiper-button-next'),
					prevEl: el.querySelector('.swiper-button-prev')
				},
				pagination: {
					el: el.querySelector('.swiper-pagination'),
					type: 'bullets',
					clickable: true
				}
			});
		});
	})();
}

// Thumb slider
var thumbSliders = document.querySelectorAll('.thumb-slider');
if (thumbSliders.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		thumbSliders.forEach(el => {
			var galleryThumbs = new Swiper(el.querySelector('.gallery-thumbs'), {
				slidesPerView: 6,
				grabCursor: false,
				allowTouchMove: false
			});
			var galleryTop = new Swiper(el.querySelector('.gallery-top'), {
				slidesPerView: 1,
				effect: 'fade',
				keyboard: {
					enabled: true
				},
				navigation: {
					nextEl: el.querySelector('.swiper-button-next'),
					prevEl: el.querySelector('.swiper-button-prev')
				},
				thumbs: {
					swiper: galleryThumbs
				}
			});
		});
	})();
}

// Tab slider
var tabBlock = document.querySelectorAll('.tab-block');
if (tabBlock.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		tabBlock.forEach(el => {
			const columnBlock = el.querySelector('.tab-block__items'),
				swiperItem = columnBlock.querySelectorAll('.tab-block__item'),
				swiperContainer = document.createElement('div'),
				swiperPagination = document.createElement('div');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);

			swiperPagination.setAttribute('class', 'swiper-pagination');
			swiperContainer.prepend(swiperPagination);

			swiperItem.forEach(si => {
				si.setAttribute('class', 'swiper-slide');
			});

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 1,
				spaceBetween: 48,
				keyboard: {
					enabled: true
				},
				pagination: {
					el: el.querySelector('.swiper-pagination'),
					clickable: true,
					renderBullet: function (index, className) {
						return '<span class="' + className + '">' + swiperItem[index].querySelector('h3').innerText + "</span>";
					}
				}
			});
		});
	})();
}

// Other products slider
var otherProductsSlider = document.querySelectorAll('.other-products-slider');
if (otherProductsSlider.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		otherProductsSlider.forEach(el => {
			const columnBlock = el.querySelector('.columns'),
				swiperItem = columnBlock.querySelectorAll('.column'),
				swiperContainer = document.createElement('div'),
				swiperPagination = document.createElement('div');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);

			swiperPagination.setAttribute('class', 'swiper-pagination');
			swiperContainer.appendChild(swiperPagination);

			swiperItem.forEach(si => {
				si.setAttribute('class', 'swiper-slide');
			});

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 1,
				spaceBetween: 48,
				keyboard: {
					enabled: true
				},
				pagination: {
					el: el.querySelector('.swiper-pagination'),
					clickable: true
				},
				breakpoints: {
					640: {
						slidesPerView: 2
					}
				}
			});
		});
	})();
}


// Close dropdown on click
document.querySelectorAll('dl.languages > dt, dl.list > dt .information').forEach(el => {
	el.addEventListener('click', () => {
		el.parentElement.classList.add('clicked');
		el.parentElement.classList.toggle('open');
	});

	var close = el.parentElement.querySelector(".close");
	if (close) {
		close.addEventListener("click", () => {
			el.parentElement.classList.toggle('open');
		});
	}

	el.addEventListener('blur', () => {
		// Timeout is required for iOS
		setTimeout(() => {
			el.parentElement.classList.remove('clicked');
			el.parentElement.classList.remove('open');
		}, 0);
	});

	// Prevent mousedown in next sibling (dd) to prevent click event to bubble to blur
	el.nextElementSibling.addEventListener('mousedown', e => {
		e.preventDefault();
	});

	el.addEventListener('keydown', e => {
		// Space and Enter
		if (e.keyCode === 13 || e.keyCode === 32) {
			el.click();
			e.preventDefault();
		}
	});
});

// Lightgallery
async function loadLightGallery() {
	const lightGallery = (await import(/* webpackMode: "eager" */ './modules/lightgallery.js')).default;

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		animateThumb: false,
		toggleThumb: false,
		showThumbByDefault: true,
		thumbMargin: 16,
		fullScreen: true,
		actualSize: false,
		hash: false,
		videoMaxWidth: '1920px'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=modal]');
if (elementsWithModal.length) {
	loadLightGallery();
}

// Jump to submitted form
document.querySelectorAll('.form').forEach(el => {
	var form = el.querySelector('form'),
		button = el.querySelector('.button');

	form.addEventListener('submit', function () {
		button.classList.add('is-loading');
	});
});