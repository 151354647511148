/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import autocomplete from 'autocomplete.js';

// Catalog search auto-complete
const catalogSearchInput = document.querySelector('.search .input[data-autocomplete]');
if (catalogSearchInput) {
	const url = catalogSearchInput.dataset.autocomplete + '&culture=' + encodeURIComponent(document.documentElement.lang) + '&queryTerms=',
		emptyText = catalogSearchInput.dataset.autocompleteEmpty,
		footerText = catalogSearchInput.dataset.autocompleteFooter,
		footerUrl = catalogSearchInput.form.action + '?q=';

	var totalResults = 0;

	autocomplete(catalogSearchInput, {
		cssClasses: {
			root: 'autocomplete',
			noPrefix: true
		},
		//debug: true
	}, [{
		source: async (query, callback) => {
			const res = await fetch(url + encodeURIComponent(query));
			const json = await res.json();

			// Store total results
			totalResults = json.count;

			return callback(json.results);
		},
		name: 'product',
		displayKey: 'name',
		templates: {
			empty: () => {
				if (!emptyText) return;

				return '<p class="empty">' + emptyText + '</p>';
			},
			footer: (context) => {
				if (context.isEmpty || !footerText) return;

				return '<p class="footer"><a href="' + footerUrl + encodeURIComponent(context.query) + '" class="arrow">' + footerText + ' (' + totalResults + ')</a></p>';
			},
			suggestion: (suggestion) => {
				var test = '<a href="' + suggestion.url + '">' +
					'<figure>' +
					(suggestion.imageUrl ? '<img src="' + suggestion.imageUrl + '" width="103" height="69" alt="" />' : '') +
					'<figcaption' + (suggestion.caption ? ' class="has-description"' : '') + '>' +
					'<h4>' + suggestion.name + '</h4>' +
					(suggestion.caption ? '<span>' + suggestion.caption + '</span>' : '') +
					'</figcaption>' +
					'</figure>' +
					'</a>';
				console.log(test);
				return test;
			}
		},
		debounce: 300
	}]).on('autocomplete:selected', (event, suggestion, dataset, context) => {
		// Do nothing on click, as the browser will already do it
		if (!context || context.selectionMethod === 'click') {
			return;
		}

		// Change the page on other events
		window.location.assign(suggestion.url);
	}).on('autocomplete:shown', () => {
		catalogSearchInput.parentElement.classList.add('open');
	}).on('autocomplete:closed', () => {
		catalogSearchInput.parentElement.classList.remove('open');
	});
}